<template>
    <div>
        <header>
            <!-- <van-nav-bar title="诚邀你加入小鹏汽车鹏友会"/> -->
        </header>

        <body>
            <van-row type="flex" justify="end" id="logobar">
                <van-image width="150" height="50" fit="contain" :src="imgLogo" style="margin-top:5px; margin-right:15px"/>
            </van-row>
            <van-row>
                <p style="margin-bottom:0px; margin-left: 30px; font-size: 40px; color: rgb(16,77,238)">你好</p>
                <h1 style="margin-top:2px; margin-bottom:15px; margin-left: 30px; font-size: 40px; color: rgb(16,77,238);">鹏友</h1>
                <p style="margin-bottom:0px; margin-top:10px; margin-left: 30px; font-size: 20px; color: rgb(16,77,238)">诚邀你加入小鹏汽车鹏友会，</p>
                <p style="margin-top:0px; margin-left: 30px; font-size: 20px; color: rgb(16,77,238)">与鹏同行，探索更多精彩。</p>
                <h1 style="margin-top:40px; margin-bottom:0px; margin-left: 30px; font-size: 23px; color: rgb(16,77,238);">鹏友会是什么</h1>
                <p style="margin-top:5px; margin-left: 20px; margin-right:20px; margin-bottom:0px; margin-left: 30px; font-size: 12px; color: rgb(108,109,109);">鹏友会是全国小鹏车主交流与活动的共创平台，通过社群和活</p>
                <p style="margin-top:5px; margin-left: 20px; margin-right:20px; margin-bottom:15px; margin-left: 30px; font-size: 12px; color: rgb(108,109,109);">动，满足车主用车交流、线下聚会、探索智能新鲜事物等需求。</p>
                <h1 style="margin-top:40px; margin-bottom:0px; margin-left: 30px; font-size: 23px; color: rgb(16,77,238);">鹏友会专属权益</h1>
            </van-row>
            <van-row style="margin-left:30px; margin-right:30px; margin-top: 10px">
                <span class="label-gray-bold">车主专礼</span>
                <span class="label-gray-normal">小鹏APP积分 + 鹏友值，不定期车主礼盒</span>
            </van-row>
            <van-row style="margin-left:30px; margin-right:30px; margin-top: 0px">
                <span class="label-gray-bold">用车交流</span>
                <span class="label-gray-normal">用车疑问轻松解决、OTA信息优先获取</span>
            </van-row>
            <van-row style="margin-left:30px; margin-right:30px; margin-top: 0px">
                <span class="label-gray-bold">车主活动</span>
                <span class="label-gray-normal">自驾娱乐等各类聚会、鹏厂发布会优先参与权</span>
            </van-row>
            <van-popup v-model="show" round position="bottom" >
                <van-picker item-height="70" title="请选择你所在的地区" show-toolbar :columns="areaList" style="margin-top:20px; margin-left: 10px; margin-right: 10px; border-radius: 10px" @confirm="confirmHandle" @cancel="cancelHandle"/>
            </van-popup>
            <van-row type="flex" style="margin-top:30px; margin-left:30px; align-item: center;">
                <van-button round type="info" block style="margin-right:30px; margin-top: 15px" @click="showPopup">加入鹏友会</van-button>

                <span>
                    <!-- <van-image width="100" height="100" fit="contain" :src="imgQRCode"/> -->
                </span>
                <!-- <span>
                    <p class="label-blue-bold" style="margin-top:10px; margin-bottom:0px; margin-left: 10px">扫码加入鹏友会</p>
                    <p class="label-blue-normal" style="margin-top:10px; margin-bottom:0px; margin-left:10px">长按本图保存到相册，微信打开扫码关注公众号</p>
                    <p class="label-blue-normal" style="margin-top:5px; margin-bottom:5px; margin-left:10px">回复您所在省份即可</p>
                </span> -->
            </van-row>
            <van-row class="circle" style="margin-top:60px">
                <van-row type="flex" align="bottom">
                    <van-image width="100" height="25" fit="scale-down" :src="imgLogo6" style="margin-top:15px; margin-left:10px"/>
                    <span class="label-blue-bold" style="margin-left: 5px;">车主志愿者</span>
                </van-row>
                <h1 class="label-blue-normal" style="padding-left: 15px;">对未知探索，做一路前行的先行者</h1>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=423009&token=4c4c34df"><van-image style="margin-top: 10px" width="100%" height="150px" fit="scale-down" :src="imgStory1"/></a>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=409775&token=4c4c34df"><van-image style="margin-top: 10px; padding-bottom:10px" width="100%" height="150px" fit="scale-down" :src="imgStory2"/></a>
            </van-row>
            <van-row class="circle" style="margin-top:60px">
                <van-row type="flex" align="bottom">
                    <van-image width="100" height="25" fit="scale-down" :src="imgLogo4" style="margin-top:15px; margin-left:15px"/>
                </van-row>
                <h1 class="label-blue-normal" style="padding-left: 15px;">汇聚好奇心，探索更大的世界</h1>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=451653&token=4c4c34df"><van-image style="margin-top: 10px" width="100%" height="150px" fit="scale-down" :src="imgStory3"/></a>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=508497&token=4c4c34df"><van-image style="margin-top: 10px; padding-bottom:10px" width="100%" height="150px" fit="scale-down" :src="imgStory4"/></a>
            </van-row>
            <van-row class="circle" style="margin-top:60px">
                <van-row type="flex" align="bottom">
                    <span class="label-blue-bold" style="margin-left: 15px; margin-top: 15px">鹏厂探秘</span>
                </van-row>
                <h1 class="label-blue-normal" style="padding-left: 15px;">共 “鹏” 见证，每一个高光时刻</h1>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=530687&token=4c4c34df"><van-image style="margin-top: 10px" width="100%" height="150px" fit="scale-down" :src="imgStory5"/></a>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=555157"><van-image style="margin-top: 10px; padding-bottom:10px" width="100%" height="150px" fit="scale-down" :src="imgStory6"/></a>
            </van-row>
            <van-row class="circle" style="margin-top:60px">
                <van-row type="flex" align="bottom">
                    <span class="label-blue-bold" style="margin-left: 15px; margin-top: 15px">X-stories</span>
                </van-row>
                <h1 class="label-blue-normal" style="padding-left: 15px;">记录真实的鹏友故事</h1>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=495835&token=4c4c34df"><van-image style="margin-top: 10px" width="100%" height="150px" fit="scale-down" :src="imgStory7"/></a>
                <a href="https://bbs.xiaopeng.com/v2/landing/bbsDetail.html?tid=442841"><van-image style="margin-top: 10px; padding-bottom:10px" width="100%" height="150px" fit="scale-down" :src="imgStory8"/></a>
            </van-row>
            <van-row type="flex" justify="center">
                <van-image width="230" height="230" fit="scale-down" :src="imgExplore"/>
            </van-row>
            <van-row type="flex" justify="center">
                <a href="http://beian.miit.gov.cn/" target="_blank" style="color:blue; margin-bottom:20px; font-size: 12px"> <p>粤ICP备2021120626号-1</p> </a>
            </van-row>
        </body>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            str_img: "",
            imgLogo: require('../assets/images/logo.png'),
            imgLogo2: require('../assets/images/logo2.png'),
            imgLogo4: require('../assets/images/logo4.png'),
            imgLogo6: require('../assets/images/logo6.png'),
            imgQRCode: require('../assets/images/qrcode.png'),
            imgStory1: require('../assets/images/story1.png'),
            imgStory2: require('../assets/images/story2.png'),
            imgStory3: require('../assets/images/story3.png'),
            imgStory4: require('../assets/images/story4.png'),
            imgStory5: require('../assets/images/story5.png'),
            imgStory6: require('../assets/images/story6.png'),
            imgStory7: require('../assets/images/story7.png'),
            imgStory8: require('../assets/images/story8.png'),
            imgExplore: require('../assets/images/exploreforever.png'),
            areaList: [
                    '安徽', // A
                    '澳门', // A
                    '北京',// B
                    '重庆',// C
                    '福建', // F
                    '甘肃',// G
                    '广东', // G
                    '广西', // G
                    '贵州',// G
                    '湖南',// H
                    '海南', // H
                    '河南',// H
                    '湖北', // H
                    '河北',// H
                    '江苏', // J
                    '江西', // J
                    '浙江', // J
                    '吉林',// J
                    '黑龙江',// J
                    '辽宁',// L
                    '内蒙',// N
                    '青海',// Q
                    '上海', // S
                    '山西',// S
                    '山东',// S
                    '四川',// S
                    '天津',// T
                    '台湾', // T
                    '西藏',// X
                    '陕西',// X
                    '新疆',// X
                    '香港', // X
                    '云南',// Y
            ]
        }
    },
    methods: {
        showPopup() {
            this.show = true;
        },
        jumpSelect () {
            this.$router.push('./selectCity')
        },
        confirmHandle(values) {
            switch (values){
                //福建、江西、广东、广西、海南、香港、台湾、澳门
                case "福建":
                case "江西":
                case "广东":
                case "广西":
                case "海南":
                case "香港":
                case "台湾":
                case "澳门":
                    this.str_img = "south";
                    break;
                //北京、天津、河北、山西、内蒙、辽宁、吉林、黑龙江、山东
                case "北京":
                case "天津":
                case "河北":
                case "山西":
                case "内蒙":
                case "辽宁":
                case "吉林":
                case "黑龙江":
                case "山东":
                    this.str_img = "north";
                    break;
                //河南、湖北、湖南、重庆、四川、贵州、云南、西藏、陕西、甘肃、青海、宁夏、新疆
                case "河南":
                case "湖北":
                case "湖南":
                case "重庆":
                case "四川":
                case "贵州":
                case "云南":
                case "西藏":
                case "陕西":
                case "甘肃":
                case "青海":
                case "宁夏":
                case "新疆":
                    this.str_img = "west";
                    break;
                //上海、江苏、浙江、安徽
                case "上海":
                case "江苏":
                case "浙江":
                case "安徽":
                    this.str_img = "east";
                    break;
            }
            console.log("value : " + values);
            this.$router.push({ name: 'selectResult', params: { userId: this.str_img }});
            //this.$router.push({name : 'selectResult' , params : { str : this.str_img}})
        },
        cancelHandle() {
            this.show = false;
        }
    }
}
</script>

<style scoped>
#logobar{
    align-items: center;
}
#logo2{
    border-left: 0.5px solid rgb(103, 103, 103);
    border-left-width: 1px;
    padding-left: 10px;
}
.label-gray-bold{
    font-size: 12px;
    font-weight: bold;
    color: rgb(103, 103, 103);
}
.label-gray-normal{
    font-size: 12px;
    color: rgb(103, 103, 103);
    border-left: 2px solid rgb(103, 103, 103);
    margin-left: 7px;
    padding-left: 7px;
}
.label-blue-bold{
    font-size: 17px;
    font-weight: bold;
    color: rgb(16,77,238);
}
.label-blue-normal{
    font-size: 11px;
    color: rgb(16,77,238);
}
.circle{
    width: 85%;
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    
    border-radius: 10px;
}
</style>